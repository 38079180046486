<template>
  <div class="new-clients-container">
    <!-- <admin-header-vue alias="" pageName="New Organization" /> -->
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column">
            <div class="display-flex">
              <upload-vue
                label="Light Logo"
                :submitted="submitted"
                :required="true"
                :image="newClient.properties.logo_light"
                @onChangeImage="onChangeLightLogo" />
              <upload-vue
                label="Dark Logo"
                :submitted="submitted"
                :required="false"
                :image="newClient.properties.logo_dark"
                @onChangeImage="onChangeDarkLogo" />
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <input-vue
              :required="true"
              :value="newClient.name"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Organization Name" />
          </div>
        </div>
                <div class="columns">
          <div class="column is-12">
            <input-vue
              :value="newClient.slug"
              :required="true"
              :min="1"
              type="text"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeSlug"
              label="Slug (iNava Domain)" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <input-vue
              :required="false"
              :value="newClient.siteUrl"
              :min="0"
              type="url"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeUrl"
              label="Website URL" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="form-group">
              <p class="display-flex">
                Owned Products
              </p>
              <dropdown-wrap-vue @onClickOutside="onClickOutside">
                <div class="relative">
                  <b-input
                    type="search"
                    ref="products"
                    @focus="onFocus"
                    :icon-right="openProduct ? 'menu-up' : 'menu-down'"
                    v-model="searchProduct"
                    class="form-control" />
                  <!-- <div
                    v-if="submitted && !newClient.productId.length"
                    class="required-error">
                    Owned Products is required
                  </div> -->
                  <common-selection-vue
                    :searchText="searchProduct"
                    name="product_name"
                    @focus="onFocus"
                    :open="openProduct"
                    :selected="newClient.productId"
                    @onChange="onChangeProductId"
                    :list="productList" />
                </div>
                <closable-tags-vue
                  @onChange="onRemoveProductName"
                  name="product_name"
                  :selected="newClient.productId" />
              </dropdown-wrap-vue>
            </div>
          </div>
        </div>
        <div class="columns">
         <div class="column is-12">
            <calendar-vue
              label="Subscription Start"
              :value="newClient.startSubscription"
              :submitted="submitted"
              :required="true"
              :max="newClient.endSubscription"
              @onChange="onChangeStart"
              placeholder="" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <calendar-vue
              label=" Subscription End"
              :value="newClient.endSubscription"
              @onChange="onChangeEnd"
              :min="newClient.startSubscription"
              :required="true"
              :submitted="submitted"
              placeholder="" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button form-group">
              <b-button @click.prevent="handleSubmit(false)">Add</b-button>
              <b-button @click.prevent="handleSubmit(true)">Add & Edit</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import calendarVue from '../../components/calendar.vue'
import InputVue from '../../components/Input.vue'
import UploadVue from '../../components/Upload.vue'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import CommonSelectionVue from '../../../../components/Dropdowns/CommonSelection.vue'
import ClosableTagsVue from '../../components/ClosableTags.vue'

export default {
  name: 'Clients',
  components: {
    AdminHeaderVue,
    InputVue,
    calendarVue,
    UploadVue,
    DropdownWrapVue,
    CommonSelectionVue,
    ClosableTagsVue
  },
  data () {
    return {
      newClient: {
        startSubscription: null,
        endSubscription: null,
        name: null,
        properties: {
          logo_light: null,
          logo_dark: null,
          module_map: {}
        },
        lightLogoName: null,
        darkLogoName: null,
        siteUrl: null,
        slug: null,
        productId: []
      },
      submitted: false,
      isValidOrganizationName: true,
      isValidUrl: false,
      isValidSlug: true,
      isLightLogoValid: true,
      openProduct: false,
      searchProduct: null,
      isDarkLogoValid: false,
      isStarSubscriptionDateValid: true,
      isEndSubscriptionDateValid: true
    }
  },
  computed: {
    ...mapState('clients', ['clientList']),
    ...mapState('products', ['productList'])
  },
  mounted () {
    this.getProductList()
  },
  methods: {
    ...mapActions('clients', ['getClientList', 'addClient']),
    ...mapActions('products', ['getProductList']),
    onChangeStart (date, isValid) {
      this.newClient.startSubscription = date
      this.isStarSubscriptionDateValid = isValid
    },
    onChangeEnd (date, isValid) {
      this.newClient.endSubscription = date
      this.isEndSubscriptionDateValid = isValid
    },
    onChangeName (name, isValid) {
      this.newClient.name = name
      this.isValidOrganizationName = isValid
      this.newClient.slug = this.generateSlug(name || '')
    },
    generateSlug (name) {
      return name
        .toString()
        .trim()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '')
    },
    onChangeUrl (name, isValid) {
      this.newClient.siteUrl = name
      this.isValidUrl = isValid
    },
    onChangeSlug (name, isValid) {
      this.newClient.slug = name
      this.isValidSlug = isValid
    },
    onChangeLightLogo ({ file, name }, isValid) {
      this.newClient.properties.logo_light = file
      this.newClient.lightLogoName = name
      this.isLightLogoValid = isValid
    },
    onChangeDarkLogo ({ file, name }, isValid) {
      this.newClient.properties.logo_dark = file
      this.newClient.darkLogoName = name
      this.isDarkLogoValid = isValid
    },
    onClickOutside () {
      this.openProduct = false
    },
    onChangeProductId (name, isValid) {
      this.newClient.productId = name
    },
    onRemoveProductName (object) {
      this.newClient.productId = this.newClient.productId.filter(
        (id) => id.product_id !== object.product_id
      )
    },
    onFocus () {
      this.$refs.products.$el.firstChild.focus()
      this.openProduct = true
    },
    async handleSubmit (editable) {
      this.submitted = true
      if (!(this.isValidOrganizationName || this.isValidUrl || this.isValidSlug ||
        this.isDarkLogoValid || this.isLightLogoValid || this.isStarSubscriptionDateValid || this.isEndSubscriptionDateValid)) {
        const object = {
          startSubscription: this.newClient.startSubscription,
          endSubscription: this.newClient.endSubscription,
          name: this.newClient.name,
          properties: this.newClient.properties,
          siteUrl: this.newClient.siteUrl,
          slug: this.newClient.slug,
          lightLogoName: this.newClient.lightLogoName,
          darkLogoName: this.newClient.darkLogoName,
          productId: this.newClient.productId.map((object) => object.product_id),
          editable
        }
        const res = await this.addClient(object)
        if (res) {
          this.submitted = false
          this.newClient = {
            startSubscription: null,
            endSubscription: null,
            name: null,
            properties: {
              logo_light: null,
              logo_dark: null
            },
            siteUrl: null,
            slug: null,
            productId: [],
            lightLogoName: null,
            darkLogoName: null
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .column {
         .relative {
          position: relative;
        }
        .required-error {
        color: red;
        position: absolute;
        font-size: 12px;
      }
        .display-flex {
          display: flex;
          margin: 0px;
          color: #7f8fa4;
          font-size: 12px;
          font-family: Quicksand;
          padding: 0px 20px 0px 0px;
          display: flex;
          line-height: 20px;
          .astrik {
            color: red;
          }
        }
        .display-flex div:nth-child(2) {
          padding-left: 20px;
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
          }
          .button:nth-child(1) {
            margin-right: 5px;
          }
          .button:nth-child(2) {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
