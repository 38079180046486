<template>
  <div class="clients-container">
    <modal-vue @onChange="onChangeModal" :modal="modal">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Delete Organization</p>
          <div class="modal-data">
          <p>
            Are you sure you want to delete this organization (<b>{{deleteClientObject.client_name}}</b>) ?
          </p>
          <div class="actions">
            <b-button @click="onChangeModal(false)">Cancel</b-button>
            <b-button @click="removeSubFun">Delete</b-button>
          </div>
          </div>
        </div>
      </div>
    </modal-vue>
    <!-- <admin-header-vue alias="Organization" @onCreate="onCreate" pageName="" /> -->
    <div class="user-table">
      <table-vue
        :labels="labels"
        :dateFormat="dateFormat"
        :data="clients"
        :sortObj="sort"
        :totalCount="totalCount"
        :currentPage="currentPage"
        :limit="limit"
        :sortValidation="sortValidation"
        :titleData="'Organizations'"
        :canDelete="false"
        :showEditAction="showEdit"
        :showDeleteAction="showDelete"
        :showAction="showEdit || showDelete || showRead"
        :showViewAction="showRead"
        :onView="onView"
        @onDelete="onDelete"
        @searchText="searchText"
        @onUpdate="onUpdate"
        @onChangeSort="onChangeSort"
        @updatePage="updatePage"
        @updateLimit="updateLimit"
        @onClick="onClick" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import tableVue from '../../components/table.vue'
// import AdminHeaderVue from '../../components/AdminHeader.vue'
import { metaTitle } from '../../../../constant/data'
import ModalVue from '../../components/Modal.vue'

export default {
  name: 'Clients',
  metaInfo () {
    return {
      title: metaTitle.organizations
    }
  },
  components: {
    tableVue,
    ModalVue
    // AdminHeaderVue
  },
  data () {
    return {
      dateFormat: ['subscription_start', 'subscription_end'],
      labels: [{ name: 'Organization', alias: 'client_name', custom: true }, { name: 'Subscription', alias: 'subscription' }],
      sortValidation: ['client_name', 'subscription_start', 'subscription_end'],
      search: '',
      sort: { by: 'asc', field: 'client_name' },
      modal: false,
      deleteClientObject: {}
    }
  },
  computed: {
    ...mapState('clients', ['clients', 'totalCount']),
    ...mapState('adminCommon', ['tableSelectionState']),
    ...mapState('user', ['userDetails']),
    showEdit () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.update)].length) {
        return true
      }
      return false
    },
    showRead () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.read)].length) {
        return true
      }
      return false
    },
    showDelete () {
      if ([...this.userDetails.permissions.filter((obj) => this.$router.history.current.path.includes(obj.path) && obj.delete)].length) {
        return true
      }
      return false
    },
    limit: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.organization.limit : 10
      },
      set (newLimit) {
        this.tableSelectionState.organization.limit = newLimit
      }
    },
    currentPage: {
      get () {
        return this.tableSelectionState ? this.tableSelectionState.organization.currentPage : 1
      },
      set (newPage) {
        this.tableSelectionState.organization.currentPage = newPage
      }
    }
  },
  mounted () {
    // console.log(, 'router')
    this.getData()
  },
  methods: {
    ...mapActions('clients', ['getClientListWithPagination', 'deleteClient']),
    labelsFun () {
      return this.clients.length ? Object.keys(this.clients[0]) : []
    },
    onClick (data) {
      // this.$router.replace(`client/${data.client_id}`)
    },
    onUpdate (data) {
      this.$router.push(`client/update/${data.client_id}`)
    },
    onCreate () {
      this.$router.push('client/add')
    },
    onView (obj) {
      this.$router.push(`client/view/${obj.client_id}`)
    },
    onChangeModal (value) {
      this.modal = value
    },
    onDelete (data) {
      this.deleteClientObject = data
      this.modal = true
    },
    async removeSubFun () {
      const res = await this.deleteClient(this.deleteClientObject.client_id)
      if (res === 200) {
        this.modal = false
        this.getData()
      }
    },
    onChangeSort (type) {
      this.sort = type
      this.getData()
    },
    updatePage (data) {
      this.currentPage = data
      this.getData()
    },
    updateLimit (data) {
      this.limit = data
      this.getData()
    },
    searchText (text) {
      this.search = text
      if (this.search !== null) {
        this.currentPage = 1
        this.getData()
      }
    },
    getData () {
      this.getClientListWithPagination({ limit: this.limit, skip: (this.currentPage - 1) * this.limit, orderBy: this.sort, search: this.search })
    }
  }
}
</script>

<style lang="scss" scoped>
.clients-container {
  .user-delete-modal {
    display: flex;
    justify-content: center;
    .user-delete-inside {
      display: flex;
      color: var(--secondary-text-color);
      font-family: Quicksand;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 400px;
      height: 200px;
      padding: 25px;
      .modal-data {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    p {
      font-size: 14px;
    }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }

    }
    .actions {
      padding-top: 20px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7F8FA466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #FF0000;
        color: white;
        &:hover {
          background-color: #F64A4A;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #EE1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
  .user-table {
    background: var(--primary);
        margin-bottom: 20px;
        border-radius: 4px;

  }
}
</style>
