<template>
    <div class="columns client-container">
          <div v-if="canCreate" class="column is-6">
              <add-client-vue />
          </div>
          <div :class="[`column ${canCreate?'is-6':'is-12'}`]">
              <clients-vue />
          </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AddClientVue from './AddClient.vue'
import ClientsVue from './Clients.vue'

export default {
  name: 'ClientsContainer',
  components: {
    AddClientVue,
    ClientsVue
  },
  computed: {
    ...mapState('user', ['userDetails']),
    canCreate () {
      const combined = this.$router.history.current.path + '/add'
      if ([...this.userDetails.permissions.filter((obj) => combined.includes(obj.path) && obj.write)].length) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.client-container {
    padding-top: 20px;
}
.columns {
    .column:nth-child(1) {
        padding:0px 5px 0px 0px;
    }
    .column:nth-child(2) {
        padding:0px 0px 0px 5px;
    }
}
</style>
